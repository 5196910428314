<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $tc('sms.send-msg.tabs.contact.title', 2) }}
          </h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="input-group">
            <input
              type="text"
              class="form-control to-left"
              :placeholder="$t('generic-str.search-by.name')"
              v-model="form.name"
            />
            <button
              type="button"
              class="btn btn-yup-purple-outline to-right btn-search"
              @click="search()"
            >
              {{ $t('generic-str.filter') }}
            </button>
          </div>
          <br />
          <contacts-table isSendPage="true" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-yup-purple" @click="confirm">
            {{ $t('generic-str.add') }}
          </button>
          <button type="button" class="btn btn-yup-purple-outline" @click="hide">
            {{ $t('generic-str.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/mixins/Modal';
import ContactsTable from '@/components/contacts/ContactsTable.vue';

export default {
  components: {
    ContactsTable,
  },
  mixins: [Modal],
  props: ['id'],
  data() {
    return {
      form: {
        name: '',
      },
      selectedContacts: [],
    };
  },
  methods: {
    selectedContactsChange(param) {
      this.selectedContacts = param.selectedContacts;
    },
    confirm() {
      this.$root.$emit('contacts.addonsend', {
        selectedContacts: this.selectedContacts,
      });
      this.hide();
    },
    search() {
      this.$root.$emit('contacts.search', { q: this.form.name });
    },
    refreshSelected(param) {
      this.selectedContacts = param.selectedContacts;
    },
  },
  mounted() {
    this.$root.$on('contacts.selected', this.selectedContactsChange);
    this.$root.$on('contacts.selected.refresh', this.refreshSelected);
  },
};
</script>

<style scoped lang="scss">
.btn-search {
  margin-left: 8px;
}
.responsive-table {
  max-height: 300px;
  overflow-y: auto;
}
</style>
