<template>
  <div class="cropper">
    <span @click="show">
      <slot />
    </span>
    <div
      v-show="isShowing"
      class="modal"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-backdrop fade show" @click="hide"></div>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="zmdi zmdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="save" class="form-horizontal">
              <div class="row">
                <div v-show="!previewImage" class="col-md-12 col-lg-12">
                  <img
                    ref="cropView"
                    :src="image"
                    style="width: 100%; max-width: 100%; max-height: 350px"
                  />
                  <div
                    v-show="!image"
                    id="drag-box-upload"
                    class="drag-box-upload"
                  >
                    <div class="dropz shadow-card" id="dropz">
                      <i class="fas fa-download"></i>
                      <p class="zone-text">
                        Arraste ou clique aqui para selecionar o arquivo.
                      </p>
                      <input
                        name="file"
                        type="file"
                        id="dropz-file"
                        accept="image/jpeg, image/png"
                        @change="readFile"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="previewImage" class="up-preview">
                  <div class="up-image">
                    <img :src="previewImage" />
                  </div>
                  <div class="up-progress">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        :style="{ width: `${uploadProgress}%` }"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary btn-outline"
              @click="hide"
            >
              Cancelar
            </button>
            <button
              v-if="image"
              type="button"
              @click="crop"
              class="btn btn-primary"
            >
              Aplicar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import BucketService from '@/services/bucket.service';

export default {
  data() {
    return {
      fileName: '',
      isShowing: false,
      image: null,
      previewImage: null,
      uploadProgress: 0,
    };
  },
  methods: {
    show() {
      this.isShowing = true;
      document.body.classList.add('modal-open');
    },
    hide() {
      this.isShowing = false;
      document.body.classList.remove('modal-open');
    },
    readFile(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      this.fileName = file.name;

      reader.onload = (e) => {
        if (!e.target.result) this.srcImage = e.target.result;
        this.$nextTick(this.initCropper());
      };
      reader.readAsDataURL(file);

      if (this.cropper) {
        this.cropper.destroy();
      }

      this.image = window.URL.createObjectURL(file);
    },
    initCropper() {
      this.cropper = new Cropper(this.$refs.cropView, {
        crop: this.updatePreview(),
        viewMode: 1,
        dragMode: 'move',
        cropBoxMovable: false,
        autoCropArea: 1,
        zoom: -10,
        movable: true,
        zoomable: true,
        cropBoxResizable: false,
        zoomOnWheel: true,
        wheelZoomRatio: 0.1,
        aspectRatio: 2,
        responsive: true,
        maxCropBoxWidth: 300,
        maxCropBoxHeight: 168,
      });
    },
    destroyCropper() {
      this.hide();
      this.cropper.destroy();
      this.image = null;
      this.previewImage = null;
      this.uploadProgress = 0;
    },
    crop() {
      this.cropper.getCroppedCanvas().toBlob((blob) => {
        this.previewImage = window.URL.createObjectURL(blob);
        this.upload(blob);
      });
    },
    upload(blob) {
      const data = new FormData();

      data.append('files[]', blob, this.fileName);
      data.append('role', 'public');
      data.append('folder', 'rcs');

      BucketService.upload(data, true, this.callBackProgress).then(
        (response) => {
          this.$emit('upload', response.data[0]);
          setTimeout(this.destroyCropper, 1000);
        },
      );
    },
    callBackProgress(progress) {
      this.uploadProgress = progress;
    },
    updatePreview() {
      console.log(111111111);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  display: block;
  .modal-dialog {
    z-index: 9999;
  }
}
.dropz {
  background: #fafafa;
  border-radius: 5px;
  border: 1px dashed rgb(183, 188, 192);
  border-image: none;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-height: 250px;
  overflow: hidden;
  cursor: pointer;
}

.dropz input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.dropz p {
  text-align: center;
  font-size: 20px;
  color: #ccc;
}

.h3-type {
  position: absolute;
  text-align: center;
  display: block;
  width: 100%;
  bottom: 0px;
  background: #00000059;
  color: #fff;
  font-size: 15px;
  padding: 3px;
}

.drop-img {
  width: 20%;
  float: left;
  position: relative;
  border-radius: 5px;
  height: 90px;
  margin: 5px;
  overflow: hidden;
  border: solid 1px #ccc;
  background: #fff;
  box-shadow: 2px 2px 2px #ccc;
}

.drop-img .p-remove {
  position: absolute;
  color: #fff;
  right: 0;
  display: block;
  text-align: center;
  padding: 2px;
  padding-left: 8px;
  padding-right: 8px;
  background: #ec2d38;
}
.drop-img .p-remove:hover {
  cursor: pointer;
  background: #d52b35;
}
.drop-img img {
  width: 100%;
  min-height: 100%;
}

.file-unic {
  float: none !important;
  margin: 0 auto !important;
  margin-top: 10% !important;
}
.fa-download {
  text-align: center;
  padding-top: 10%;
  font-size: 60px;
  display: block;
  color: #ccc;
}
.up-progress {
  position: absolute;
  width: 80%;
  top: 50%;
  left: 10%;
}
</style>
