<template>
  <div class="card-footer text-right">
    <div v-for="(button, n) in buttons" :key="n" class="form-group row">
      <hr v-if="n > 0" />
      <div class="col-md-6">
        <select-input
          label="name"
          class="template-style"
          :reduce="(option) => option.value"
          v-model="button.type"
          :options="options"
          @input="handleInput"
        />
      </div>
      <div class="col-md-6">
        <div class="input-group">
          <input
            placeholder="Texto"
            v-model="button.text"
            type="text"
            class="form-control"
            @input="handleInput"
          />
          <div class="input-group-append">
            <button
              class="btn btn-primary btn-icon"
              type="button"
              @click="removeButton(n)"
            >
              <span class="material-icons edit_square_icon"> close </span>
            </button>
          </div>
        </div>
        <div v-if="button.type === 'url'" class="input-group mt-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-icon-addon1"
              ><i class="icon dripicons-web"></i
            ></span>
          </div>
          <input
            placeholder="Link"
            v-model="button.url"
            type="text"
            class="form-control"
            @input="handleInput"
          />
        </div>
        <div v-else-if="button.type === 'location'" class="input-group mt-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-icon-addon1"
              ><i class="icon dripicons-location"></i
            ></span>
          </div>
          <input
            type="text"
            class="form-control"
            placeholder="Localização..."
            v-model="button.address"
          />
          <iframe
            v-if="button.address"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            id="gmap_canvas"
            :src="`https://maps.google.com/maps?amp;hl=en&amp;q=${button.address}&amp;t=&amp;z=19&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`"
            width="520"
            height="400"
            frameborder="0"
          ></iframe>
        </div>
        <div v-if="button.type === 'event'">
          <div class="input-group mt-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-icon-addon1"
                ><i class="icon dripicons-calendar"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control"
              placeholder="Titulo do Evento"
              v-model="button.title"
            />
          </div>
          <div class="input-group mt-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-icon-addon1"
                ><i class="icon dripicons-calendar"></i
              ></span>
            </div>
            <date-range-picker
              class="form-control"
              :format="'YYYY-MM-DD hh:mm'"
              :options="calendarOptions"
              @change="changeDate($event, button)"
            />
          </div>
        </div>
        <div v-show="button.type === 'dial'" class="input-group mt-3">
          <vue-tel-input
            v-model="button.phone_number"
            v-bind="phoneOptions"
          ></vue-tel-input>
        </div>
      </div>
    </div>
    <div class="form-actions text-right">
      <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-primary btn-rounded"
            @click="addButton"
            :disabled="buttons.length > 3"
          >
            Adicionar Botão<i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { VueTelInput } from 'vue-tel-input';
import SelectInput from '@/components/SelectInput.vue';

export default {
  name: 'RcsButton',
  components: {
    SelectInput,
    VueTelInput,
  },
  props: {
    label: {
      type: String,
      default: 'Button',
    },
    value: {
      type: Array,
    },
  },
  computed: {
    buttons: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      options: [
        {
          name: 'Resposta',
          value: 'reply',
        },
        {
          name: 'Discagem',
          value: 'dial',
        },
        {
          name: 'Localização',
          value: 'location',
        },
        {
          name: 'Evento',
          value: 'event',
        },
        {
          name: 'Link',
          value: 'url',
        },
      ],
      calendarOptions: {
        timePicker24Hour: true,
        timePicker: true,
        autoApply: true,
        className: 'form-control',
        startDate: moment().format('DD/MM/YYYY'),
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      phoneOptions: {
        autoFormat: true,
        mode: 'international',
        defaultCountry: 'BR',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: true,
        placeholder: this.$t('generic-str.lbl-cellphone'),
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        validCharactersOnly: true,
        preferredCountries: ['AU', 'BR'],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 25,
        wrapperClasses: '',
        inputClasses: 'form-control',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      text: '',
    };
  },
  created() {
  },
  methods: {
    addButton() {
      this.buttons.push({
        text: 'text',
        type: 'reply',
        start_time: moment().startOf('day').format(),
        end_time: moment().endOf('day').format(),
      });
      this.$emit('input', this.buttons);
    },
    removeButton(i) {
      this.buttons.splice(i, 1);
      this.$emit('input', this.buttons);
    },
    handleInput() {
      this.$emit('input', this.buttons);
    },
    changeDate(date, button) {
      if (date) {
        console.log(date);
        button.start_time = moment(date[0]).startOf('day').format();
        button.end_time = moment(date[1]).endOf('day').format();
      }
    },
  },
};
</script>
<style scoped>
.vue-tel-input {
  border-color: #dfe7f3 !important;
  border: 1px solid rgba(120, 141, 180, 0.3) !important;
}
iframe {
  width: 100%;
  min-height: 200px;
}
</style>
