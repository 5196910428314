<template>
  <div class="content" :class="{ 'content-blur': locked }">
    <slot />
    <div v-if="locked" >
      <div role="document" class="content-lock">
        <div >
          <div class=" text-center">
            <img
              src="assets/img/no_plan.png" style="filter: none !important;"
            />
            <h4 class="content-lock-title">{{title}}</h4>
            <p>
              {{message}}
            </p>
            <ul class="columns-2">
              <li v-for="(feature, i) in features" :key="i">{{feature.text}}</li>
            </ul>
            <button type="button" class="btn btn-primary" @click="unsubscribeIt">
              Habilitar <i class="fas fa-unlock text-white"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page',
  props: {
    featureTitle: {
      type: String,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Modal',
    },
    message: {
      type: String,
      default: '',
    },
    features: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  methods: {
    unsubscribeIt() {
      this.$emit('unsubscribeIt');
    },
  },
};
</script>

<style scoped lang="scss">
.content-blur {
  height: 100vh;
  position: relative;
  header,
  .card,
  img {
    filter: blur(5px);
  }
  .modal-content {
    padding: 0;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
  }
  .modal-body {
    border-top: 1px solid #e2e4e9;
    padding: 32px 0 0 40px;
  }
}
.modal {
  display: block;
  position: absolute;
  .modal-dialog {
    margin: 9rem auto;
  }
}
.content-lock {
  position: absolute;
  top: 130px;
  left: 18%;
  width: 800px;
}
.content-lock-title {
  font-weight: 400;
  font-size: 20px;
  margin-top: 14px;
}
.content-lock p{
  font-size: 14px;
  font-weight: 400;
  color: #595959;
}
@media (max-width: 700px){
  .content-lock {
    width: 100%;
    left: 0%;
  }
}
</style>
