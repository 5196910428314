<template>
  <Page :locked="locked" :title="'Você ainda não possui este canal ativo'" :message="'O RCS ajuda você a enviar conteúdo rico diretamente para o aplicativo Mensagens do seu cliente. Compartilhe fotos, arquivos e eventos, saiba quando as mensagens são lidas e construa um relacionamento com seus clientes.'" :features="features" :featureTitle="'O que tem no RCS?'" @unsubscribeIt="subscribeRCS">
    <PageHeader :title="'RCS Google'" :subtitle="$t('generic-str.send')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-lg-7">
          <div class="card card-tabs">
            <div class="card-header">
              <ul class="nav nav-tabs primary-tabs">
                <li class="nav-item nav-m-custom" role="presentation">
                  <a
                    href="javascript:void(0)"
                    class="nav-link"
                    v-tab="'simple'"
                    :class="{ active: form.type == 'simple' }"
                    data-toggle="tab"
                    aria-expanded="true"
                    @click="changeType('simple')"
                    ><span class="material-symbols-outlined"> edit </span
                    >{{ $t('sms.send-msg.tabs.simple.title') }}</a
                  >
                </li>
                <li class="nav-item nav-m-custom" role="presentation">
                  <a
                    href="javascript:void(0)"
                    class="nav-link"
                    v-tab="'contact'"
                    aria-expanded="true"
                    @click="changeType('contacts')"
                    :class="{ active: form.type == 'contacts' }"
                    ><i class="far fa-address-book"></i
                    >{{ $tc('sms.send-msg.tabs.contact.title', 2) }}</a
                  >
                </li>
                <li class="nav-item nav-m-custom" role="presentation">
                  <a
                    href="javascript:void(0)"
                    class="nav-link"
                    v-tab="'group'"
                    aria-expanded="true"
                    :class="{'active': form.type == 'groups'}"
                    @click="changeType('groups')"
                    ><span class="material-symbols-outlined"> group </span
                    >{{ $t('sms.send-msg.tabs.groups.title') }}</a
                  >
                </li>
                <li class="nav-item nav-m-custom" role="presentation">
                  <a
                    href="javascript:void(0)"
                    class="nav-link"
                    v-tab="'files'"
                    aria-expanded="true"
                    @click="changeType('files')"
                    ><span class="material-symbols-outlined"> file_upload </span
                    >{{ $t('sms.send-msg.tabs.archives.title') }}</a
                  >
                </li>
              </ul>
            </div>
            <div class="card-body">
              <form @submit.prevent class="form-horizontal">
                <div class="form-body">
                  <div class="row">
                    <div class="col-lg-12">
                      <!-- <div v-if="form.type != 'simple'" class="form-group row"> -->
                      <div
                        class="form-group row campaign-group"
                        v-if="form.type != 'simple'"
                      >
                        <label class="control-label text-left col-3"
                          >{{ $t('sms.send-msg.lbl-campaign') }}:</label
                        >
                        <div class="col-md-9">
                          <input
                            v-model="form.name"
                            class="form-control campaign-input"
                          />
                        </div>
                      </div>
                      <div class="form-group row sender-group">
                        <div class="col-lg-12 template-wrapper">
                          <label class="control-label text-left col-3 pl-0">
                            {{ $t('sms.send-msg.lbl-sender') }}:
                            <!-- Remetente -->
                          </label>
                          <select-input
                            label="name"
                            class="template-style"
                            :reduce="(agent) => agent.id"
                            v-model="form.agentId"
                            :options="agents"
                            v-on:option:selected="changeAgent"
                          />
                        </div>
                      </div>
                      <div class="tab-content">
                        <div
                          class="tab-pane active"
                          id="simple"
                          :class="{ active: form.type == 'simple' }"
                        >
                          <div class="form-group row recipient-group">
                            <div
                              class="col-lg-12 d-flex align-items-center flex-wrap recipient-row"
                            >
                              <label class="mr-3 mb-0"
                                >{{
                                  $t('sms.send-msg.tabs.simple.lbl-recipient')
                                }}:</label
                              >
                              <textarea
                                v-model="form.to"
                                rows="1"
                                class="form-control col"
                                v-bind:class="{
                                  invalid:
                                    submited &&
                                    form.to.length == 0 &&
                                    form.type == 'simple',
                                }"
                                :placeholder="
                                  this.$t(
                                    'sms.send-msg.tabs.simple.lbl-example',
                                  )
                                "
                              />
                            </div>
                            <!-- <div class="col-md-12 pl-0">
                              <div class="form-floating floating-transparent">
                                <textarea
                                  v-model="form.to"
                                  rows="6"
                                  class="form-control"
                                  v-bind:class="{
                                    invalid:
                                      submited &&
                                      form.to.length == 0 &&
                                      form.type == 'simple',
                                  }"
                                  :placeholder="
                                    this.$t(
                                      'sms.send-msg.tabs.simple.lbl-example',
                                    )
                                  "
                                />
                                <label class="control-label text-left col-12">{{$t('sms.send-msg.tabs.simple.lbl-recipient',)}}:</label>
                              </div>
                            </div> -->
                          </div>
                        </div>
                        <div
                          class="tab-pane"
                          id="contact"
                          :class="{ active: form.type == 'contacts' }"
                        >
                          <div class="form-group row">
                            <div class="col-md-12 block-el">
                              <div class="add-contacts">
                                <label
                                  class="control-label text-left col-4 pl-0"
                                  >{{
                                    $tc('sms.send-msg.tabs.contact.title', 2)
                                  }}:</label
                                >
                                <button
                                  class="btn btn_add"
                                  type="button"
                                  v-modal="{ target: 'contact-send-modal' }"
                                >
                                  <span
                                    class="material-symbols-outlined add_person"
                                  >
                                    person_add
                                  </span>
                                  <!-- Adicionar Contato -->
                                </button>
                                <hr />
                                <b-collapse
                                  id="collapse-1"
                                  visible
                                  class="mt-2 col-lg-12"
                                >
                                  <b-card class="send-group-card">
                                    <div
                                      class="text-center table-placeholder"
                                      v-if="contacts.length == 0"
                                    >
                                      <!-- <i class="fas fa-user font-size-80"></i> -->
                                      <LottieAnimNoData />
                                      <h5 class="card-title m-t-20">
                                        {{
                                          $t(
                                            'sms.send-msg.tabs.contact.lbl-none-contact',
                                          )
                                        }}
                                      </h5>
                                    </div>
                                    <p
                                      class="card-text"
                                      v-if="contacts.length > 0"
                                    >
                                      Contatos adicionados ({{
                                        contacts.length
                                      }})
                                    </p>
                                    <ul
                                      class="ul-list-groups"
                                      v-if="contacts.length > 0"
                                    >
                                      <li
                                        v-for="(contact, i) in contacts"
                                        :key="contact.id"
                                      >
                                        <div
                                          class="alert alert-secondary alert-outline alert-dismissible fade show"
                                          role="alert"
                                        >
                                          {{ contact.first_name }} /
                                          {{ contact.mobile_number }}
                                          <button
                                            type="button"
                                            class="close"
                                            @click="removeContact(i)"
                                          >
                                            <span
                                              aria-hidden="true"
                                              class="fas fa-times"
                                            ></span>
                                          </button>
                                        </div>
                                      </li>
                                    </ul>
                                  </b-card>
                                </b-collapse>
                              </div>
                              <!-- <group-table isSendPage="true" />-->
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="group" :class="{ active: form.type == 'groups' }">
                          <div class="form-group row">
                            <div class="col-md-12 block-el">
                              <div class="add-contacts">
                                <label
                                  class="control-label text-left col-4 pl-0"
                                  >{{
                                    $t('sms.send-msg.tabs.groups.title')
                                  }}:</label
                                >
                                <button
                                  class="btn btn_add"
                                  type="button"
                                  v-modal="{ target: 'group-send-modal' }"
                                >
                                  <span
                                    class="material-symbols-outlined add_person"
                                  >
                                    group_add
                                  </span>
                                  <!-- Adicionar Grupo -->
                                </button>
                                <hr />
                                <b-collapse
                                  id="collapse-1"
                                  visible
                                  class="mt-2 col-lg-12"
                                >
                                  <b-card class="send-group-card">
                                    <div
                                      class="text-center table-placeholder"
                                      v-if="groups.length == 0"
                                    >
                                      <!-- <i class="fas fa-users font-size-80"></i> -->
                                      <LottieAnimNoData />
                                      <h5 class="card-title m-t-20">
                                        {{
                                          $t(
                                            'sms.send-msg.tabs.groups.lbl-none-group',
                                          )
                                        }}
                                      </h5>
                                    </div>
                                    <p
                                      class="card-text"
                                      v-if="groups.length > 0"
                                    >
                                      {{
                                        $t(
                                          'sms.send-msg.tabs.groups.lbl-added-groups',
                                        )
                                      }}
                                      ({{ groups.length }})
                                    </p>
                                    <ul
                                      class="ul-list-groups"
                                      v-if="groups.length > 0"
                                    >
                                      <li
                                        v-for="(group, i) in groups"
                                        :key="group.id"
                                      >
                                        <div
                                          class="alert alert-secondary alert-outline alert-dismissible fade show"
                                          role="alert"
                                        >
                                          {{ group.name }}
                                          <button
                                            type="button"
                                            class="close"
                                            @click="removeGroup(i)"
                                          >
                                            <span
                                              aria-hidden="true"
                                              class="fas fa-times"
                                            ></span>
                                          </button>
                                        </div>
                                      </li>
                                    </ul>
                                  </b-card>
                                </b-collapse>
                              </div>
                              <!-- <group-table isSendPage="true" />-->
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="files">
                          <div class="form-group row">
                            <div
                              v-if="!importing"
                              class="col-md-12 p-0 add-contacts"
                            >
                              <label class="control-label text-left col-3">{{
                                $t('sms.send-msg.tabs.archives.lbl-add-archive')
                              }}</label>
                              <input
                                ref="uploadedFile"
                                type="file"
                                accept=".csv"
                                hidden
                                @change="uploadFile"
                              />
                              <div class="col-lg-9 archive-opt">
                                <div class="form-control form-file">
                                  {{ form.file }}
                                </div>
                                <button
                                  class="btn btn-import"
                                  type="button"
                                  @click="openFileUpload"
                                >
                                  <template v-if="form.file">{{
                                    $t(
                                      'sms.send-msg.tabs.archives.lbl-change-archive',
                                    )
                                  }}</template>
                                  <template v-else
                                    ><span
                                      class="material-symbols-outlined icon_title"
                                      >file_upload</span
                                    ></template
                                  >
                                </button>
                                <a
                                  href="/exemplos/example.csv"
                                  class="btn btn-yup-purple ml-3"
                                  v-tooltip.top="
                                    $t(
                                      'sms.send-msg.tabs.archives.lbl-tooltip-archive',
                                    )
                                  "
                                  >{{ $t('generic-str.example') }}</a
                                >
                              </div>
                            </div>
                            <div v-else class="col-md-6">
                              <div class="row">
                                <div class="col-12">
                                  <small>{{
                                    $t(
                                      'sms.send-msg.tabs.archives.lbl-validating',
                                    )
                                  }}</small>
                                </div>
                              </div>
                              <div class="progress">
                                <div
                                  class="progress-bar progress-bar-striped progress-bar-animated"
                                  role="progressbar"
                                  :style="`width: ${uploadProgress}%;`"
                                  aria-valuenow="25"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  {{ uploadProgress }}%
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group contents-form">
                        <div class="row">
                          <div class="col">
                            <label for="sms-body"
                              >{{
                                $t('sms.send-msg.sms-input-component.title')
                              }}:
                            </label>
                            <!-- Conteúdo -->
                          </div>
                          <div class="col text-right">
                            <b-dropdown
                              class="dropdown-badge mr-2"
                              v-if="variables.length"
                              variant="transparent p-0"
                            >
                              <template #button-content>
                                <span class="badge btn-yup-purple">{{
                                  $t(
                                    'sms.send-msg.sms-input-component.lbl-variables',
                                  )
                                }}</span>
                              </template>
                              <b-dd-item
                                v-for="variable in variables"
                                :key="variable.value"
                                @click="addVariable(variable)"
                              >
                                {{ variable.name }}
                              </b-dd-item>
                            </b-dropdown>
                            <Emojis
                              v-if="form.messageType === 'text'"
                              @select="addEmoji"
                              title="Emoji"
                            />
                          </div>
                        </div>
                        <textarea
                          v-if="form.messageType === 'text'"
                          class="form-control text-area-sms"
                          rows="6"
                          maxlength="612"
                          id="sms-body"
                          v-model="form.body.plain.text"
                        ></textarea>
                        <div v-else-if="form.messageType === 'media'">
                          <div class="alert alert-warning">
                            A url precisa ser do tipo extensão .jpg, .jpeg,
                            .png, .mp3 ou .mp4
                          </div>
                          <input
                            v-model="form.body.plain.url"
                            type="text"
                            placeholder="Link da Mídia:"
                            class="form-control"
                          />
                        </div>
                        <div v-else-if="form.messageType == 'card'">
                          <div class="row">
                            <div class="col-9">
                              <div class="card">
                                <div class="card-content">
                                  <div
                                    class="card-img h-200 d-flex"
                                    :class="{
                                      'h-200': form.body.card.size === 'SMALL',
                                      'h-300': form.body.card.size === 'MEDIUM',
                                      'h-400': form.body.card.size === 'TALL',
                                    }"
                                  >
                                    <img
                                      class="card-img-top img-fluid"
                                      :src="form.body.card.url"
                                      alt="Card image cap"
                                    />
                                    <ImageUploader
                                      @upload="
                                        (file) =>
                                          (form.body.card.url = file.url)
                                      "
                                    >
                                      <i
                                        class="fas fa-edit card-edit-btn absolute"
                                      ></i>
                                    </ImageUploader>
                                  </div>
                                  <div class="card-body">
                                    <h4 class="card-title">
                                      <input
                                        class="form-control"
                                        placeholder="Titulo do Card"
                                        v-model="form.body.card.title"
                                      />
                                    </h4>
                                    <p class="card-text">
                                      <textarea
                                        class="form-control"
                                        placeholder="Descrição"
                                        v-model="form.body.card.description"
                                      />
                                    </p>
                                  </div>
                                  <RcsButtons
                                    v-model="form.body.card.buttons"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else-if="form.messageType == 'carousel'">
                          <div class="row carousel">
                            <div
                              v-for="(card, n) in form.body.carousel.cards"
                              :key="n"
                              class="col-6"
                            >
                              <div class="card">
                                <div class="card-content">
                                  <div class="card-img h-200 d-flex">
                                    <img
                                      class="card-img-top img-fluid"
                                      :src="card.image"
                                      alt="Card image cap"
                                    />
                                    <ImageUploader
                                      @upload="
                                        (file) => (card.image = file.url)
                                      "
                                    >
                                      <i
                                        class="fas fa-edit card-edit-btn absolute"
                                      ></i>
                                    </ImageUploader>
                                    <a
                                      v-if="form.body.carousel.cards.length > 2"
                                      role="button"
                                      class="text-white absolute float-right"
                                      @click="removeCard(n)"
                                    >
                                      <span
                                        class="material-icons edit_square_icon"
                                      >
                                        close
                                      </span>
                                    </a>
                                  </div>
                                  <div class="card-body">
                                    <h4 class="card-title">
                                      <input
                                        class="form-control"
                                        placeholder="Titulo do Card"
                                        v-model="card.title"
                                      />
                                    </h4>
                                    <p class="card-text">
                                      <textarea
                                        class="form-control"
                                        placeholder="Descrição"
                                        v-model="card.description"
                                      />
                                    </p>
                                  </div>
                                  <RcsButtons
                                    v-model="card.buttons"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-actions text-right">
                            <div class="row">
                              <div class="col-md-12">
                                <button
                                  class="btn btn-primary btn-rounded"
                                  @click="addCard"
                                  :disabled="form.body.carousel.cards.length > 9"
                                >
                                  Adicionar Card<i class="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-lg-4 col-md-8 col-sm-12"
                      style="display: none"
                    >
                      <div class="card card-border-info">
                        <div class="card-header p-l-20 p-b-15 p-t-15">
                          {{ $t('options-div.title') }}
                        </div>
                        <div class="card-body">
                          <div class="form-group row">
                            <div class="col-md-12">
                              <div
                                class="custom-control custom-checkbox custom-control-inline custom-control-inline-send"
                              >
                                <input
                                  class="tgl tgl-light tgl-primary calendar-change"
                                  id="schedule"
                                  type="checkbox"
                                  v-model="form.schedule"
                                  name="schedule"
                                />
                                <label class="tgl-btn" for="schedule"></label>
                                <label class="label-send-check">{{
                                  $t('options-div.lbl-schedule')
                                }}</label>
                              </div>
                              <date-picker
                                v-if="form.schedule"
                                v-model="form.scheduledAt"
                                :config="options"
                                class="form-control input-date"
                                :placeholder="$t('generic-str.date-hour')"
                              ></date-picker>
                            </div>
                            <div class="col-md-12 hide">
                              <div
                                class="custom-control custom-checkbox custom-control-inline custom-control-inline-send"
                              >
                                <input
                                  class="tgl tgl-light tgl-primary calendar-change"
                                  id="pubSub"
                                  type="checkbox"
                                  v-model="form.pub_sub"
                                  name="pubSub"
                                />
                                <label class="tgl-btn" for="pubSub"></label>
                                <label class="label-send-check">{{
                                  $t('send-component.allow-response')
                                }}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-footer">
              <div class="card-footer-wrapper">
                <div class="alert alert-warning" v-if="!this.selectedAgent">
                  {{ $t('generic-str.client-inactive') }}
                  <strong
                    ><a
                      href="https://yupchat.typeform.com/to/b0aG4Maq"
                      target="_blank"
                      >{{ $t('generic-str.request-access') }}</a
                    ></strong
                  >
                </div>
                <div class="right-items">
                  <ul
                    class="nav nav-send-type"
                    id="pills-demo-3"
                    role="tablist"
                  >
                    <li class="nav-item">
                      <a
                        class="nav-link btn-floating c-pointer"
                        :class="{ active: tabs == 'text' }"
                        @click="changeTextTab('text')"
                        v-b-tooltip.hover.bottom="{
                          customClass: 'items-tooltip',
                        }"
                        :title="$tc('options-div.types.text', 1)"
                      >
                        <span class="material-symbols-outlined"
                          >text_fields</span
                        >
                        <!-- {{ $tc('options-div.types.text', 1) }} -->
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link btn-floating c-pointer"
                        :class="{ active: tabs == 'media' }"
                        @click="changeTextTab('media')"
                        v-b-tooltip.hover.bottom="{
                          customClass: 'items-tooltip',
                        }"
                        :title="$t('options-div.types.media')"
                      >
                        <span class="material-icons">image</span>
                        <!-- {{ $t('options-div.types.media') }} -->
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link btn-floating c-pointer"
                        :class="{ active: tabs == 'card' }"
                        @click="changeTextTab('card')"
                        v-b-tooltip.hover.bottom="{
                          customClass: 'items-tooltip',
                        }"
                        :title="$t('options-div.types.card')"
                        ><span class="material-symbols-outlined">pages</span>
                        <!-- {{ $t('options-div.types.card') }} -->
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link btn-floating c-pointer"
                        :class="{ active: tabs == 'card-carousel' }"
                        @click="changeTextTab('carousel')"
                        v-b-tooltip.hover.bottom="{
                          customClass: 'items-tooltip',
                        }"
                        :title="$t('options-div.types.carousel')"
                        ><span class="material-icons">view_carousel</span>
                        <!-- {{ $t('options-div.types.carousel') }} -->
                      </a>
                    </li>
                    <!--<li class="nav-item">
                      <a
                        class="nav-link btn-floating c-pointer"
                        :class="{ active: tabs == 'link' }"
                        @click="changeTextTab('link')"
                        v-b-tooltip.hover.bottom="{
                          customClass: 'items-tooltip',
                        }"
                        :title="$t('options-div.types.links')"
                        ><i class="fas fa-link"></i
                        >
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link btn-floating c-pointer"
                        :class="{ active: tabs == 'calendar' }"
                        @click="changeTextTab('calendar')"
                        v-b-tooltip.hover.bottom="{
                          customClass: 'items-tooltip',
                        }"
                        :title="$t('options-div.types.events')"
                        ><span class="material-icons">calendar_month</span>
                      </a>
                    </li>-->
                  </ul>
                  <!-- <span class="material-symbols-outlined title attach">
                    attach_file
                  </span>
                  <i class="fas fa-smile title"></i> -->
                  <div class="calendar_form">
                    <date-picker
                      v-if="form.schedule"
                      v-model="form.scheduledAt"
                      :config="options"
                      v-b-tooltip.hover
                      :title="form.scheduledAt"
                      class="form-control input-date"
                      :placeholder="$t('generic-str.date-hour')"
                    ></date-picker>
                  </div>
                  <span
                    class="material-icons text-yup calendar_icon"
                    @click="this.form.schedule = true"
                  >
                    watch_later
                  </span>
                  <button
                    @click="send"
                    type="button"
                    :class="{
                      'qt-loader qt-loader-mini qt-loader-right': isSending,
                      hide: !this.selectedAgent,
                    }"
                    :disabled="isSending || importing"
                    class="btn btn-yup-purple float-right ml-3"
                  >
                    {{ $t('generic-str.send') }}
                  </button>
                  <!-- <b-dropdown
                    @click="send"
                    type="button"
                    dropup
                    :class="{
                      'qt-loader qt-loader-mini qt-loader-right': isSending,
                      hide: !this.client.active,
                    }"
                    :disabled="isSending || importing"
                    split
                    variant="yup-purple"
                    :text="$t('generic-str.send')"
                  >
                    <template #button-content>
                      {{ $t('generic-str.send') }}
                    </template>
                    <b-dropdown-item v-model="form.birthDate">
                      {{ $t('options-div.schedule-delivery') }}
                      <span class="material-icons">watch_later</span>
                    </b-dropdown-item>
                  </b-dropdown> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg preview-div">
          <div class="preview-wrapper">
            <div class="card card-preview">
              <div class="card-body">
                <p class="card-text" v-if="rcs.text.content.length">
                  {{ rcs.text.content }}
                </p>
                <p v-else class="card-text">
                  Olá cliente 🤩 Voce ganhou um Cupom de desconto valido ate o
                  dia xxx.
                </p>
              </div>
            </div>
            <img
              class="phone-img"
              src="@/assets/phone.svg"
              alt="Phone Background"
            />
          </div>
        </div>
      </div>
    </div>
    <create-contact-modal id="create-contact"></create-contact-modal>
    <create-group-modal id="create-group"></create-group-modal>
    <group-send-modal id="group-send-modal"></group-send-modal>
    <contact-send-modal id="contact-send-modal"></contact-send-modal>
    <m-crop id="m-crop" />
  </Page>
</template>

<script>
// @ is an alias to /src
import axios from '@/plugins/axios';
import RCSService from '@/services/rcs.service';
import Tab from '@/directives/Tab';
import Page from '@/components/Page.vue';
import SelectInput from '@/components/SelectInput.vue';
import ImageUploader from '@/components/ImageUploader.vue';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import Utils from '@/models/utils';
import CreateContactModal from '@/components/contacts/CreateContactModal.vue';
import GroupSendModal from '@/components/rebranding/groups/GroupSendModal.vue';
import ContactSendModal from '@/components/rebranding/contacts/ContactSendModal.vue';
// import GroupTable from '@/components/groups/GroupTable.vue';
import CreateGroupModal from '@/components/groups/CreateGroupModal.vue';
import Swal from 'sweetalert2';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import $ from 'jquery';
import moment from 'moment';
import Emojis from '@/components/rebranding/Emojis.vue';
import MCrop from '@/components/MCrop.vue';
import BucketService from '@/services/bucket.service';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';
import RcsButtons from '@/components/rcs/RcsButtons.vue';

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});

export default {
  name: 'RcsSend',
  components: {
    Page,
    PageHeader,
    CreateContactModal,
    ContactSendModal,
    GroupSendModal,
    CreateGroupModal,
    datePicker,
    Emojis,
    MCrop,
    SelectInput,
    LottieAnimNoData,
    ImageUploader,
    RcsButtons,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      agents: [],
      selectedAgent: {},
      variables: [],
      infos: null,
      show: false,
      rcs: {
        text: {
          content: '',
        },
        media: {},
        calendar: {
          messageText: '',
          suggestions: [
            {
              action: {
                textEdit: false,
                text: this.$t('options-div.btn-text'),
                postbackData: this.$t('options-div.lbl-response'),
                createCalendarEventAction: {
                  nstartTime: '2020-06-30T19:00:00Z',
                  nendTime: '2020-06-30T20:00:00Z',
                  title: this.$t('options-div.event-title'),
                  titleEdit: false,
                  description: this.$t('options-div.event-description'),
                  descriptionEdit: false,
                },
              },
            },
          ],
        },
        links: {
          messageText: '',
          suggestions: [
            {
              action: {
                text: this.$t('generic-str.action'),
                postbackData: 'Resposta 1',
                openUrlAction: {
                  url: 'https://www.google.com',
                },
              },
            },
          ],
        },
        richCard: {
          titleEdit: false,
          messageEdit: false,
          messageText: this.$t('options-div.card-title'),
          messageDescription: this.$t('options-div.card-text'),
          imageUrl:
            'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png', // '/assets/img/default_card_bg.jpeg',
          height: 'TALL',
          suggestions: [
            {
              reply: {
                text: this.$t('generic-str.action'),
                postbackData: 'Ok',
              },
            },
          ],
        },
        richCarousels: {
          images: [
            {
              titleEdit: false,
              messageEdit: false,
              title: this.$t('options-div.card-title'),
              description: this.$t('options-div.card-text'),
              uri: 'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png',
              height: 'MEDIUM',
              postbackData: 'Ok',
              suggestions: [
                {
                  action: {
                    text: 'Link',
                    postbackData: 'Resposta 1',
                    openUrlAction: {
                      url: 'https://www.google.com',
                    },
                  },
                },
              ],
            },
            {
              titleEdit: false,
              messageEdit: false,
              title: this.$t('options-div.card-title'),
              description: this.$t('options-div.card-text'),
              uri: 'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png',
              height: 'MEDIUM',
              postbackData: 'Ok',
              suggestions: [
                {
                  action: {
                    text: 'Link',
                    postbackData: 'Resposta 1',
                    openUrlAction: {
                      url: 'https://www.google.com',
                    },
                  },
                },
              ],
            },
          ],
        },
      },
      tabs: 'text',
      cropActive: {},
      addVars: false,
      hasVar: false,
      groups: [],
      isSending: false,
      contacts: [],
      selectedGroups: [],
      selectedContacts: [],
      selectedFiles: [],
      importing: false,
      vars: [],
      value: null,
      fileVariables: [],
      files: {
        richCard: {},
        richCarousels: [],
      },
      indexFile: 0,
      form: {
        type: 'simple',
        agentId: '',
        from: 'Default',
        pub_sub: true,
        to: '',
        contacts: [],
        groups: [],
        body: {
          plain: {
            text: '',
            url: '',
          },
          card: {
            title: '',
            description: '',
            orientation: 'VERTICAL',
            url: 'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png',
            size: 'TALL',
            alignment: 'LEFT',
            buttons: [],
          },
          carousel: {
            cards: [
              {
                title: '',
                description: '',
                image:
                  'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png',
                size: 'TALL',
                buttons: [],
              },
              {
                title: '',
                description: '',
                image:
                  'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png',
                size: 'TALL',
                buttons: [],
              },
            ],
          },
        },
        scheduledAt: null,
        file: '',
        normalize: false,
        flash: false,
        template: null,
        schedule: true,
        messageType: 'text',
      },
      options: {
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: true,
        minDate: moment(),
        locale: 'pt',
        keepInvalid: true,
      },
      submited: false,
      length: 0,
      uploadProgress: 0,
      features: [],
    };
  },
  computed: {
    locked() {
      if (this.$store.state.account.plans.rcs == null) {
        this.fetchAgents();
        return true;
      }
      return false;
      // return this.submited;
    },
  },
  created() {
    if (!this.locked) {
      this.fetchAgents();
      if (this.$route.params.contacts) {
        this.contacts = this.$route.params.contacts;
        this.contacts.forEach((contact) => {
          this.selectedContacts.push(contact.id);
        });
        this.form.type = 'contacts';
        this.changeType('contacts');
      }
      if (this.$route.params.groups) {
        this.groups = this.$route.params.groups;
        this.groups.forEach((group) => {
          this.selectedGroups.push(group.id);
        });
        this.form.type = 'groups';
        this.changeType('groups');
      }
      this.$forceUpdate();
    }
  },
  methods: {
    subscribeRCS() {
      this.$router.push('/channels');
    },
    fetchAgents() {
      this.$store
        .dispatch('fetchRcsAgents')
        .then((response) => {
          this.agents = response;
          this.form.agentId = this.agents[0].id;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeAgent(agent) {
      this.selectedAgent = agent;
    },
    crop(data) {
      this.cropActive.dataCrop = data;
      this.$root.$emit('show.modal', 'request-modal');
      if (data) {
        // this.form.body.card.url = data.url;
        if (this.tabs === 'card') {
          this.files[data.name] = data;
          document.getElementById(this.cropPreview).src = `${
            this.files[data.name].url
          }`;
        }
        if (this.tabs === 'card-carousel') {
          this.files[data.name][this.indexFile] = data;
          document.getElementById(this.cropPreview).src = `${
            this.files[data.name][this.indexFile].url
          }`;
          this.files[data.name][this.indexFile].index = this.indexFile;
        }
      }
      console.log(this.files);
    },
    callCrop(
      cropPreview,
      maxWidthP,
      maxHeightP,
      cropActive,
      nameP = null,
      index = 0,
    ) {
      this.$crop.show({
        width: 1700,
        height: 800,
      });
      /* this.cropPreview = cropPreview;
      this.cropActive = cropActive;
      this.indexFile = index;
      this.$root.$emit('show.modal', 'm-crop');
      this.$store.dispatch('crop/config', {
        maxWidth: maxWidthP,
        maxHeight: maxHeightP,
        name: nameP,
        viewMode: 1,
        aspectRatio: 2,
        minSize: true,
        infoSize: '1700x800',
      }); */
    },
    setValue(value) {
      this.$emit('input', value);
    },
    addEmoji(emoji) {
      if (this.tabs === 'text') {
        this.form.body.plain.text = `${this.form.body.plain.text}${emoji}`;
      }
      if (this.tabs === 'link') {
        this.rcs.links.messageText = `${this.rcs.links.messageText}${emoji}`;
      }
      if (this.tabs === 'calendar') {
        this.rcs.calendar.messageText = `${this.rcs.calendar.messageText}${emoji}`;
      }
    },
    addVariable(variable) {
      this.rcs.text.content = `${this.rcs.text.content}{${variable.value}}`;
    },
    changeTextTab(tab) {
      // this.tabs = tab;
      this.form.messageType = tab;
    },
    selectedGroupsChange(param) {
      param.selectedGroups.forEach((group) => {
        if (!this.form.groups.includes(group.id)) {
          this.groups.push(group);
          this.form.groups.push(group.id);
        }
      });
    },
    selectedContactsChange(param) {
      param.selectedContacts.forEach((contact) => {
        if (!this.form.contacts.includes(contact.id)) {
          this.contacts.push(contact);
          this.form.contacts.push(contact.id);
        }
      });
    },
    selectedFilesChange(param) {
      param.selectedFiles.forEach((file) => {
        if (!this.selectedFiles.includes(file.id)) {
          this.files.push(file);
          this.selectedFiles.push(file.id);
        }
      });
    },
    changeType(type) {
      if (type === 'contacts' || type === 'groups' || type === 'files') {
        this.vars = [
          { name: this.$t('generic-str.lbl-first-name'), value: 'first_name' },
          { name: this.$t('generic-str.lbl-last-name'), value: 'last_name' },
          {
            name: this.$t('generic-str.lbl-cellphone'),
            value: 'mobile_number',
          },
          { name: 'Email', value: 'email' },
        ];
      } else if (type === 'files') {
        this.vars = this.fileVariables;
      } else {
        this.vars = [];
      }
      this.form.type = type;
      this.submited = false;
    },
    openFileUpload() {
      this.$refs.uploadedFile.click();
    },
    uploadFile(event) {
      const self = this;
      const reader = new FileReader();
      this.importing = true;
      const formData = new FormData();
      const file = event.target.files[0];
      formData.append('file', file);
      this.form.file = file.name;

      reader.onload = () => {
        const variables = reader.result
          .split('\n')
          .shift()
          .replace('\r', '')
          .split(';'); // first line
        variables.forEach((variable) => {
          self.fileVariables.push({
            value: variable,
            name: variable,
          });
        });
      };
      reader.readAsText(file, 'UTF-8');

      const config = {
        onUploadProgress: (progressEvent) => {
          this.uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
        },
      };

      axios()
        .post('files', formData, config)
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.success'),
              type: 'success',
            });
          },
          (error) => {
            self.form.file = '';
            self.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.importing = false;
          this.uploadProgress = 0;
        });
    },
    removeGroup(i) {
      this.groups.splice(i, 1);
      this.form.groups.splice(i, 1);
      this.$root.$emit('groups.selected.refresh', {
        selectedGroups: this.groups,
      });
    },
    removeContact(i) {
      this.contacts.splice(i, 1);
      this.form.contacts.splice(i, 1);
      this.$root.$emit('contacts.selected.refresh', {
        selectedContacts: this.contacts,
      });
    },
    removeFile(i) {
      this.files.splice(i, 1);
      this.selectedFiles.splice(i, 1);
    },
    addVar(entity) {
      this.addVars = false;
      this.form.body = this.form.body.concat(` {${entity}} `);
      this.hasVar = true;
      this.countSegments(15);
    },
    countSegments(newValues) {
      console.log(newValues);
      this.length = this.form.body.length + newValues;
    },
    send() {
      this.isSending = true;
      this.$store
        .dispatch('storeRcsMessages', this.form)
        .then((response) => {
          if (this.form.type === 'simple') {
            this.$router.push({
              name: 'RCSSuccess',
              params: { rcs: response.data },
            });
          } else {
            this.$router.push('/rcs/reports/campaign');
          }
        })
        .catch((ex) => {
          ex.response.data.errors.forEach((error) => {
            this.$toast.show({
              title: `Error ${error.code}`,
              content: error.detail,
              type: 'danger',
            });
          });
        })
        .finally(() => {
          this.isSending = false;
        });
    },
    sendRequest() {
      Swal.fire({
        title: 'Enviar Mensagens',
        text: this.$t('warnings.send-component.send-msg-confirm'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          this.isSending = true;
          this.form.rcs = this.rcs;
          switch (this.messageType) {
            case 'text':
              this.sendMessage();
              break;
            case 'media':
              if (Utils.isMidia(this.form.rcs.media.fileUrl) !== undefined) {
                this.sendMessage();
              } else {
                this.$toast.show({
                  title: this.$t('sms.infos.filters.status.lbl-error'),
                  content: 'A url mídia é inválida',
                  type: 'danger',
                });
                this.isSending = false;
              }
              break;
            case 'link':
              this.sendMessage();
              break;
            case 'calendar':
              this.form.rcs.calendar.suggestions.forEach((element) => {
                const startTime =
                  element.action.createCalendarEventAction.nstartTime;
                const endTime =
                  element.action.createCalendarEventAction.nendTime; // 'YYYY-MM-DD HH:mm:ss'
                // moment.tz('09/06/2021 11:45', 'DD/MM/YYYY HH:mm', 'UTC').format()
                const newStartTime = moment
                  .tz(startTime, 'DD/MM/YYYY HH:mm', 'UTC')
                  .format();
                const newEndTime = moment
                  .tz(endTime, 'DD/MM/YYYY HH:mm', 'UTC')
                  .format();
                console.log(newStartTime);
                element.action.createCalendarEventAction.startTime =
                  newStartTime;
                element.action.createCalendarEventAction.endTime = newEndTime;
              });
              console.log(this.form.rcs.calendar);

              this.sendMessage();
              break;
            case 'card-carousel':
              this.isSending = true;
              if (this.files.richCarousels.length > 0) {
                const filesCrop = [];
                const formData = new FormData();
                this.files.richCarousels.forEach((element) => {
                  filesCrop.push({
                    x: element.x,
                    y: element.y,
                    w: element.width,
                    h: element.height,
                  });
                  formData.append('files[]', element.file);
                });
                formData.append('role', 'public');
                formData.append('folder', 'rcs');
                formData.append('crop', JSON.stringify(filesCrop));
                console.log(this.files.richCarousels);
                console.log(filesCrop);

                Promise.all([
                  BucketService.upload(formData, true, this.callBackProgress),
                ])
                  .then(
                    (responses) => {
                      console.log(responses);
                      let count = 0;
                      this.files.richCarousels.forEach((element) => {
                        this.rcs.richCarousels.images[element.index].uri =
                          responses[0].data[count].url;
                        delete this.rcs.richCarousels.images[element.index]
                          .dataCrop;
                        count += 1;
                      });
                      console.log(this.rcs.richCarousels);
                      // this.form.rcs.richCard.imageUrl = responses[0].data.url;
                      this.sendMessage();
                    },
                    (error) => {
                      this.content = error;
                      this.isSending = false;
                      console.log(error);
                    },
                  )
                  .finally(() => {
                    this.isSending = false;
                  });
              } else {
                this.sendMessage();
              }
              break;
            case 'card':
              this.isSending = true;
              if (
                this.files.richCard.file !== null &&
                this.files.richCard.file !== undefined
              ) {
                const formDataRich = new FormData();
                const filesCrop = [];
                filesCrop.push({
                  x: this.files.richCard.x,
                  y: this.files.richCard.y,
                  w: this.files.richCard.width,
                  h: this.files.richCard.height,
                });
                formDataRich.append('files[]', this.files.richCard.file);
                formDataRich.append('role', 'public');
                formDataRich.append('folder', 'rcs');
                formDataRich.append('crop', JSON.stringify(filesCrop));

                Promise.all([
                  BucketService.upload(
                    formDataRich,
                    true,
                    this.callBackProgress,
                  ),
                ])
                  .then(
                    (responses) => {
                      console.log(responses);
                      console.log(responses[0].data.url);
                      this.form.rcs.richCard.imageUrl =
                        responses[0].data[0].url;
                      delete this.form.rcs.richCard.dataCrop;
                      console.log(this.form);
                      this.sendMessage();
                    },
                    (error) => {
                      this.content = error;
                      this.isSending = false;
                      console.log(error);
                    },
                  )
                  .finally(() => {
                    this.isSending = false;
                  });
              } else {
                this.sendMessage();
              }
              break;
            default:
          }
        }
      });
    },
    callBackProgress(progress) {
      this.uploadProgress = progress;
    },
    addCardSuggestion() {
      this.form.body.card.suggestions.push({
        postback_data: 'post',
        text: 'text',
        type: 'reply',
      });
    },
    removeCardSuggestion(i) {
      this.form.body.card.suggestions.splice(i, 1);
    },
    addCard() {
      this.form.body.carousel.cards.push({
        title: '',
        description: '',
        image:
          'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png',
        size: 'TALL',
        buttons: [],
      });
    },
    removeCard(i) {
      this.form.body.carousel.cards.splice(i, 1);
    },
    sendMessage() {
      RCSService.send(this.form)
        .then(
          (send) => {
            if (this.form.type === 'simple') {
              this.$router.push({
                name: 'RCSSuccess',
                params: { sms: send.data },
              });
            } else {
              this.$router.push('/rcs/reports/campaign');
            }
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.msg-registered'),
              type: 'success',
            });
            this.isSending = false;
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .catch((error) => {
          console.log(error.response);
          this.isSending = false;
        });
    },
  },
  mounted() {
    this.$root.$on('groups.addonsend', this.selectedGroupsChange);
    this.$root.$on('contacts.addonsend', this.selectedContactsChange);
    this.$root.$on('files.addonsend', this.selectedFilesChange);
    this.$root.$on('call.crop', this.crop);
  },
};
</script>

<style scoped lang="scss">
.card-rich .card-rich-cover {
  height: 250px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.card-rich h2 {
  padding: 10px;
}
.card-rich h3 {
  padding: 10px;
}
.card-rich p {
  padding: 10px;
}
textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
  /* border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; */
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
/* textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
} */
.input-date {
  margin-top: 15px !important;
}
.form-control.invalid {
  border-color: #ff5c75;
}
.actions-var {
  position: relative !important;
}
.emoji-color {
  color: #a6a6a6 !important;
}
.send-group-card {
  box-shadow: none;
}
.send-group-card .table-placeholder i,
h5 {
  color: #b5b5b5;
}
.actions-var-cancel {
  text-align: center;
  color: #4d5a68;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
  padding-top: 5px;
  padding-bottom: 0px;
}
.actions-icon {
  font-size: 14px !important;
}
.actions-var a {
  cursor: pointer;
}
.custom-control-inline-send {
  margin-left: 0px !important;
  padding-left: 0px !important;
}
.label-send-check {
  margin-left: 10px;
  margin-top: 2px;
}
.ul-list-groups {
  margin: 0px;
  padding: 0px;
}
.ul-list-groups li {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.card-send-content {
  border: solid 1px transparent;
  border-radius: 10px;
  margin: 0px;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 25px;
}
.card-edit-btn {
  margin: 10px;
  color: #fff;
  cursor: pointer;
  left: 0;
}
.card-edit-btn:hover {
  color: rgb(230, 230, 230);
}
.card-c-rich-cover {
  height: 90px !important;
}
.c-gallery {
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  display: flex;
}

.c-gallery__img {
  scroll-snap-align: start;
  margin-bottom: 10px;
  min-width: 30%;
  object-fit: cover;
  height: 220px;
  margin: 10px;
}

.var-insede-input {
  margin-left: 5px;
  position: absolute;
  right: 5px;
  top: 5px;
}
.var-insede-input i {
  font-size: 15px !important;
}
/* Rebranding */
.darkmode .card-send-content .card {
  background-color: var(--background-3);
}

.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}
.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}
.nav.nav-tabs .nav-item .nav-link.active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
  color: var(--clr-light-2);
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}
.send-opt {
  color: var(--gray-font-color);
}

.send-opt .control-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.send-opt .control span {
  margin-top: 5px;
}

.card-footer-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1rem 0;
  border-top: 2px solid #00000029;
}

.right-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: fit-content;
  margin-left: auto;
}

.right-items::v-deep .dropdown-menu .dropdown-item {
  color: var(--clr-yup-purple);
}

.card-footer-wrapper i.title,
.card-footer-wrapper span.title {
  font-size: 1.4rem;
  transition: 0.5s;
  padding: 9px;
}

.card-footer-wrapper span.attach {
  transform: rotateY(180deg);
}

.card-footer-wrapper i.title:hover,
.card-footer-wrapper span.title:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.recipient-wrapper,
#recipients-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

#recipients-list li {
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  border: 2px solid #f7f7f7;
}

.darkmode #recipients-list li {
  border-color: var(--background-3);
  background-color: var(--background-2);
}

#recipients-list li .delete-item {
  font-size: 0.7rem;
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
}

#recipients-list li .delete-item:hover {
  background-color: rgba(210, 221, 234, 0.5);
}

.darkmode #recipients-list li .delete-item:hover {
  background-color: var(--background-3);
}

/* .dropup::v-deep ul.dropdown-menu.show {
  top: -180% !important;
} */

.dropup::v-deep ul.dropdown-menu {
  width: fit-content;
}

.dropup::v-deep ul.dropdown-menu a.dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.template-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.template-wrapper label {
  padding-right: 3rem;
}

.form-floating.floating-transparent textarea {
  border-color: transparent !important;
  /* border-bottom: 2px solid #00000029 !important; */
  padding-left: 0;
  margin-left: 0.8rem;
}

/* .form-floating.floating-transparent input {
  border-color: transparent !important;
} */

.form-floating label {
  margin-top: unset !important;
}

.template-style {
  font-size: 0.8rem;
}

.v-select::v-deep > div:first-child,
.campaign-input,
.form-file {
  min-width: 320px;
  max-width: 320px;
}

::v-deep .product-input select {
  width: 320px;
}

.sender-group,
.template-wrapper {
  margin-bottom: 1.5rem;
}

.campaign-group,
.product-group,
.recipient-group {
  margin-bottom: 3rem;
}

::v-deep label {
  color: var(--gray-font-color-2);
  font-weight: 400;
}
::v-deep .vs__search {
  position: absolute !important;
}

.preview-div {
  display: flex;
  position: relative;
  /* align-items: center; */
}

.preview-wrapper {
  width: 100%;
  height: fit-content;
}

.phone-img {
  margin-left: auto;
  width: 20rem;
  height: fit-content;
}

.add-contacts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.btn_add {
  width: fit-content;
  height: auto;
  padding: 0;
  margin-left: auto;
}
hr {
  width: 100%;
  border-top: 2px solid #00000029;
}
.btn-import {
  width: fit-content;
  height: auto;
  padding: 0;
  margin-left: 1.25rem;
}
.btn-import span {
  color: var(--gray-font-color);
  font-weight: 300;
}
.archive-opt {
  display: flex;
  align-items: center;
}
.darkmode .form-floating.floating-transparent textarea {
  background-color: var(--background-2);
}

.darkmode .nav-tabs .nav-link.active {
  background-color: var(--background-3);
}

.darkmode .nav.nav-tabs .nav-item .nav-link:hover,
.darkmode .nav.nav-tabs .nav-item .nav-link:hover i {
  color: #fff;
}

.nav-send-type {
  gap: 1rem;
}

.nav-send-type li {
  cursor: pointer;
}
.nav-send-type li a {
  color: var(--gray-font-color) !important;
  padding: 8px;
  transition: 0.5s;
  display: flex;
  align-items: center;
}

.nav-send-type li a:hover,
.nav-send-type li a.active {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}
.darkmode .nav-send-type li a {
  background-color: var(--background-3);
}
.nav-send-type li i {
  font-size: 24px;
  color: var(--gray-font-color) !important;
}
.message-type-title {
  display: flex;
  flex-wrap: wrap;
}
.message-type-title .text-right {
  margin-left: auto;
}
::v-deep .textarea-information {
  background-color: var(--background-2) !important;
  border-color: var(--background-2) !important;
}
::v-deep .emojis__widget {
  box-shadow: 0px 3px 6px #00000029 !important;
}
.emoji-variable-wrapper {
  margin-left: 5px;
  display: flex;
  align-items: center;
}
.card-send-content textarea {
  border-color: transparent !important;
}
.card-cover {
  padding: 6px;
}
.card-cover > img {
  width: 320px;
  border-radius: 3px;
}
.title_wrapper,
.text_wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.text_wrapper {
  margin-bottom: 12px;
}
.actions_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.actions_wrapper span {
  margin-left: unset !important;
}
.actions_wrapper ~ .actions_wrapper {
  margin-top: 12px;
}
.edit_absolute {
  right: 10px;
  color: #fff;
  cursor: pointer;
  transition: 0.5s;
  mix-blend-mode: difference;
}
.edit_absolute:hover {
  opacity: 0.5;
}
.edit_action_wrapper {
  margin-bottom: 12px;
}
.opt-carousel-card {
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  flex-wrap: wrap;
  gap: 10px;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.main-content-card {
  transition: all ease-in-out 0.5s;
  max-width: 300px;
}
.card-extra-opt:hover .main-content-card {
  transform: translateX(-40px);
}
.card-extra-opt {
  overflow: hidden;
  position: relative;
}
.extra-opt {
  transition: all ease-in-out 0.5s;
}
.card-extra-opt:hover .extra-opt {
  transform: translateX(-40px);
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.more-opt {
  position: absolute;
  right: -30px;
  top: 50%;
  padding: 6px;
  transition: 0.5s;
  z-index: 5;
}

.darkmode .btn-more-opt {
  color: #fff;
}

.card-extra-opt:hover .more-opt {
  right: 0;
}

.show-extra-opt,
.card.card-extra-opt {
  /* width: 600px !important; */
  flex-direction: row;
  flex-shrink: 0;
}

.extra-configs {
  width: 300px;
  height: 100%;
}

.not-collapsed ~ .main-content-card {
  /* left: -85px;
  margin-left: -30px; */
  max-width: 300px;
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
.extra-opt {
  overflow: auto;
  max-height: 350px;
}
.c-gallery.accordion {
  scroll-snap-type: unset !important;
  scroll-behavior: unset !important;
}

.card {
  transition: all ease-in-out 0.5s;
}

.c-gallery.accordion.highlight-card > :not(.show-card) {
  width: 220px;
  min-width: unset;
}

.card.card-extra-opt {
  width: 30%;
}

.card.card-extra-opt.show-card {
  width: 630px;
}

.title-wrapper,
.view-type {
  display: flex;
}

.view-type {
  margin-left: auto;
}

.view-type span {
  cursor: pointer;
  color: var(--gray-font-color) !important;
  padding: 8px;
  display: flex;
  align-items: center;
}

.view-type span:hover,
.view-type.grid-style span:last-child,
.view-type.list-style span:first-child {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.card-carousel-content.grid-view .c-gallery.accordion {
  flex-wrap: wrap;
}

.recipient-row .form-control {
  border-color: transparent !important;
}

.calendar_form {
  position: absolute;
  right: 146px;
  width: 25px;
  input.input-date {
    opacity: 0;
  }
}

.calendar_form:hover ~ .calendar_icon,
.calendar_form:focus-within ~ .calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 25px;
}

@media (min-width: 1360px) and (max-width: 1600px) {
  .card.card-extra-opt {
    width: 40%;
  }
}

@media (min-width: 768px) {
  ::v-deep label {
    font-size: 1rem !important;
  }
  .phone-img {
    position: absolute;
    right: 0;
    padding-right: 3rem;
    margin-top: 10%;
    z-index: -1;
  }
  .preview-wrapper {
    position: sticky;
    top: 100px;
  }
  .card-preview {
    position: absolute;
    width: 410px;
    top: 200px;
    right: 20%;
  }
  .card-preview .card-body {
    padding: 3rem 1rem;
  }
}
.popup-box-container {
  position: relative;
  display: inline-block;
  margin-right: 5px;
}
textarea {
  resize: none !important;
}
textarea:not(:last-child) {
  border-bottom: none !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
  /* border: 1px solid #dfe7f3; */
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
/* textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
} */

#sms-body {
  border-color: transparent !important;
}

.contents-form {
  border-top: 2px solid #00000029 !important;
  padding-top: 1rem;
}
.img-fluid {
  object-fit: cover;
  object-position: center;
}
span.btn {
  width: 150px;
  input {
    color: white;
    border: 0;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: 0px;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid white;
    width: 100%;
  }
}
.carousel {
  overflow-x: scroll;
  flex-wrap: nowrap;
}
.absolute.float-right {
  right: 0;
}
</style>
